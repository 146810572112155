import { JoinWaitlistCard } from './JoinWaitlistCard'
import { SocialMediaContainer } from './SocialMediaContainer'
import { Footer } from '../../components/Footer'
import { FeatureCardContainer } from '../../components/FeatureCardContainer'
import Lottie from "lottie-react";
import mobileHeroImagesJSON from '../../assets/animations/mobileHeroImages.json'
import websiteHeroImagesJSON from '../../assets/animations/websiteHeroImages.json'
import { Header } from '../../components/Header'
import { useEffect, useState } from 'react';

export const Home = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)
  const isMobile = window.innerWidth < 1024

  useEffect(() => {
    setTimeout(() => {
      setIsHeaderVisible(true)
    }, 500)
  }, [])

  // const oldHomeUI = (
  //   <div className="flex flex-col gap-8 items-center justify-center">
  //     <div className='flex flex-col gap-8 md:gap-0 h-full w-[90%] md:w-4/5'>
  //       <div className='h-[10%] w-full flex justify-between items-center my-4'>
  //         <Link to={'/'} className='h-1/2 cursor-pointer' >
  //           <img src={homeLogo} alt='KOTH home logo' className='w-[111px] md:w-[250px]' />
  //         </Link>
  //       </div>
  //       <div className='w-full md:h-[90%] flex flex-col justify-center gap-8'>
  //         <div className='w-full flex items-center gap-2'>
  //           <div className='text-[36px] md:text-[64px] font-extrabold text-white'>
  //             WELCOME TO
  //           </div>
  //           <div className='text-[36px] md:text-[64px] font-extrabold text-[#FF4752]'>
  //             KOTH Gaming
  //           </div>
  //         </div>
  //         <div className='flex w-full justify-center align-center md:hidden'>
  //         </div>
  //         <div className='w-full flex justify-center items-center gap-2'>
  //           <div>
  //             <div className='w-full text-[16px] md:text-[32px] font-extrabold text-white'>
  //               World's Premier Fantasy Sports Platform.
  //             </div>
  //           </div>
  //         </div>
  //         <div className='md:w-[90%] flex justify-start'>
  //           <JoinWaitlistCard />
  //         </div>
  //         <div className='md:w-[90%] flex justify-center'>
  //           <SocialMediaContainer />
  //         </div>
  //       </div>
  //     </div>
  //     <div className='h-[40%] flex flex-col w-[90%] md:w-full justify-end gap-4'>
  //       <div className='w-full text-[24px] md:text-[48px] text-center md:text-start font-extrabold text-white'>
  //         Enjoy Best in-class Gaming Experience with
  //       </div>
  //       <div className='w-full text-[24px] md:text-[48px] text-center md:text-start font-extrabold text-[#FF4752]'>
  //         KOTH Gaming
  //       </div>
  //     </div>
  //     <div className='h-[70%] w-4/5 md:w-full flex justify-center items-center py-8'>
  //       <img src={kothBenefits} alt='KOTH Benefits' className='max-h-full' />
  //     </div>
  //     {/* Footer */}
  //     <div className='gap-2 mb-2 md:h-[15%] w-full flex flex-col justify-around items-center md:flex-row md:h-[10%] md:justify-between'>
  //       <Footer />
  //     </div>
  //   </div>
  // )

  const mobileView = (
    <div className='w-[95%] m-auto flex flex-col justify-center items-center'>
      <Header />
      <div className={`text-[36px] font-extrabold text-white mt-8 transition-all duration-[1500ms] ${isHeaderVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
        WELCOME TO
      </div>
      <div className={`text-[36px] font-extrabold text-[#FF4752] transition-all duration-[1500ms] ${isHeaderVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
        KOTH Gaming
      </div>
      <div className={`text-[16] text-white text-center transition-all duration-[2000ms] ${isHeaderVisible ? 'opacity-100' : 'opacity-0'}`}>
        World's Premier Fantasy Sports Platform.
      </div>
      <Lottie animationData={mobileHeroImagesJSON} loop={false} />
      <div className={`text-[14px] text-white font-bold text-center`}>
        Be the first to experience <span className='font-extrabold text-[#FF4752]' >KOTH Gaming</span>
      </div>
      <div className={`text-[14px] text-white font-bold text-center`}>
        <span className='font-extrabold text-[#FF4752]' >JOIN THE WAITLIST</span> now for exclusive early access!
      </div>
      <div className='w-full py-4' >
        <JoinWaitlistCard />
      </div>
      <SocialMediaContainer />
      <div className='w-full flex flex-col justify-end my-8 gap-4'>
        <div className='w-full text-[24px] text-center font-extrabold text-white'>
          Enjoy Best in-class Gaming Experience with
        </div>
        <div className='w-full text-[24px] text-center font-extrabold text-[#FF4752]'>
          KOTH Gaming
        </div>
      </div>
      <FeatureCardContainer />
      <Footer />
      {/* Highlight Effects */}
      <div
        style={{
          position: 'absolute',
          top: '60vh',
          left: 0,
          zIndex: -1,
          height: '300px',
          width: '300px',
          background: 'radial-gradient(circle, rgba(255, 71, 81, 0.25), rgba(255,255,255,0) 75%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 75,
          right: 0,
          zIndex: -1,
          height: '250px',
          width: '250px',
          background: 'radial-gradient(circle, rgba(255, 71, 81, 0.25), rgba(255,255,255,0) 75%)',
        }}
      />
    </div>
  )

  const desktopView = (
    <div className='w-full flex flex-col justify-center items-center'>
      <div className='w-[85%] flex flex-col m-auto justify-center'>
        <Header />
        <Lottie animationData={websiteHeroImagesJSON} loop={false} className='absolute left-25 top-[-5%]' />
        <div className={`w-full flex items-center justify-start gap-4 transition-all duration-[2000ms] ${isHeaderVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-20'}`}>
          <div className='text-[64px] font-extrabold text-white'>
            WELCOME TO
          </div>
          <div className='text-[64px] font-extrabold text-[#FF4752]'>
            KOTH Gaming
          </div>
        </div>
        <div className={`text-[32px] font-extrabold text-white transition-all duration-[2000ms] ${isHeaderVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-20'}`}>
          World's Premier Fantasy Sports Platform.
        </div>
        <div className='w-2/5 pt-24 pb-36 flex flex-col gap-4 items-start z-10'>
          <div>
            <div className={`text-[18px] font-bold text-white`}>
              Be the first to experience <span className='font-extrabold text-[#FF4752]' >KOTH Gaming</span>
            </div>
            <div className={`text-[18px] font-bold text-white`}>
              <span className='font-extrabold text-[#FF4752]' >JOIN THE WAITLIST</span> now for exclusive early access!
            </div>
          </div>
          <JoinWaitlistCard />
          <SocialMediaContainer />
        </div>
        <div className='w-full flex flex-col justify-end mt-16 mb-8 gap-4'>
          <div className='w-full text-[48px] font-extrabold text-white'>
            Enjoy Best in-class Gaming Experience with
          </div>
          <div className='w-full text-[48px] font-extrabold text-[#FF4752]'>
            KOTH Gaming
          </div>
        </div>
        <FeatureCardContainer />
      </div>
      <Footer />
      {/* Highlight Effects */}
      <div
        style={{
          position: 'absolute',
          top: '75vh',
          left: 0,
          zIndex: -1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '600px',
          width: '600px',
          background: 'radial-gradient(circle, rgba(255, 71, 81, 0.25), rgba(255,255,255,0) 70%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: -1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '600px',
          width: '600px',
          background: 'radial-gradient(circle, rgba(255, 71, 81, 0.25), rgba(255,255,255,0) 70%)',
        }}
      />
    </div>
  )

  return isMobile ? mobileView : desktopView
}
