// import { useState } from "react"
import { FeatureCard } from "./FeatureCard"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import realcashJSON from '../assets/animations/realcash.json'
import topsecurityJSON from '../assets/animations/topsecurity.json'
import welcomeBonusJSON from '../assets/animations/welcomeBonus.json'
import fraudJSON from '../assets/animations/fraud.json'
import lowestfeeJSON from '../assets/animations/lowestfee.json'
import AIJSON from '../assets/animations/AI.json'
import supporterJSON from '../assets/animations/supporter.json'
import withdrawalJSON from '../assets/animations/withdrawal.json'
import { useState } from "react"

const featureCardsData = [
  {
    title: 'Win Real Cash',
    animationJson: realcashJSON
  },
  {
    title: 'Top Security',
    animationJson: topsecurityJSON
  },
  {
    title: 'Welcome Bonus',
    animationJson: welcomeBonusJSON
  },
  {
    title: 'Fraud Detection',
    animationJson: fraudJSON
  },
  {
    title: 'Lowest Entry Fee',
    animationJson: lowestfeeJSON
  },
  {
    title: 'AI Research Center',
    animationJson: AIJSON
  },
  {
    title: 'Supporter League',
    animationJson: supporterJSON
  },
  {
    title: 'Fastest Withdrawals',
    animationJson: withdrawalJSON
  },
]

export const FeatureCardContainer = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const isMobile = window.innerWidth < 1024

  const settings = {
    accessibility: false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (_: number, next: number) => setSlideIndex(next/2),
    customPaging: (i: number) => {
      return <div key={i} className={`mt-6 w-2 h-2 rounded-full bg-${i === slideIndex ? '[#FF4752]' : 'white'}`} />
    }
  };

  const mobileView = (
    <div className="w-full flex justify-around items-center mx-auto py-8 pl-[10%]">
      <div className="slider-container w-full mb-6">
        <Slider {...settings} >
          {
            featureCardsData.map((data) => {
              return (
                <FeatureCard title={data.title} animationJson={data.animationJson} />
              )
            })
          }
        </Slider>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '110vh',
          zIndex: -1,
          height: '300px',
          width: '300px',
          background: 'radial-gradient(circle, rgba(255, 71, 81, 0.25), rgba(255,255,255,0) 75%)',
        }}
      />
    </div>
  )

  const desktopView = (
    <div className="w-full grid grid-cols-4 gap-16 mt-4 mb-8">
      {
        featureCardsData.map((data, index) => {
          return (
            <FeatureCard key={index} title={data.title} animationJson={data.animationJson} />
          )
        })
      }
    </div>
  )

  return isMobile ? mobileView : desktopView
}