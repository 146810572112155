import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";

interface FeatureCardProps {
  title: string
  animationJson: any
}
export const FeatureCard = (props: FeatureCardProps) => {
  const { title, animationJson } = props
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  const isMobile = window.innerWidth < 1024

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(ref?.current as any)
        }
      },
      {
        threshold: 0.2
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const mobileView = (
    <div 
      ref={ref as any}
      className={`transition-all transform-gpu duration-1000 ${
        isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
      }`}
    >
      <div className="w-[135px] h-[175px] flex flex-col px-4 py-6 gap-4 rounded-xl justify-between items-center bg-[#20233D]">
        <div className="flex justify-center items-center w-[75px] h-[75px] p-2 rounded-xl bg-[#2B2E47]">
          <Lottie animationData={animationJson} loop className="scale-150" />
        </div>
        <div className="text-center">{title}</div>
      </div>
    </div>
  )

  const desktopView = (
    <div 
      ref={ref as any}
      className={`transition-all transform-gpu duration-500 ${
        isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
      }`}
    >
      <div className="w-[250px] h-[300px] flex flex-col px-4 py-8 gap-4 rounded-xl justify-between items-center bg-[#20233D] hover:scale-[1.15] hover:bg-[#FF4853]">
        <div className="flex justify-center items-center w-[100px] h-[100px] p-2 rounded-xl bg-[#2B2E47]">
          <Lottie animationData={animationJson} loop className="scale-150" />
        </div>
        <div className="text-center text-38 text-[38px]">{title}</div>
      </div>
    </div>
  )

  return isMobile ? mobileView : desktopView
}