import { Link } from "react-router-dom"
import homeLogo from '../assets/homeLogo.png'

export const Header = () => {
  const isMobile = window.innerWidth < 1024

  const mobileView = (
    <>
      <Link to={'/'} className='cursor-pointer py-4' >
        <img src={homeLogo} alt='KOTH home logo' className='w-[111px]' />
      </Link>
      <div className='h-[1px] w-screen bg-[#363850] opacity-30' />
    </>
  )

  const desktopView = (
    <Link to={'/'} className='w-full cursor-pointer py-8' >
      <img src={homeLogo} alt='KOTH home logo' className='w-[250px]' />
    </Link>
  )

  return isMobile ? mobileView : desktopView
}