import { Link } from "react-router-dom"
import { SocialMediaContainer } from "../screens/Home/SocialMediaContainer"

export const Footer = () => {
  const isMobile = window.innerWidth < 1024

  const mobileView = (
    <div className="w-screen flex flex-col items-center justify-around bg-[#23243D] py-4">
      <div className='text-[12px] text-white opacity-65'>
        Copyright © 2024 KOTH Gaming - All Rights Reserved
      </div>
      <div>
        <Link to={'/privacy-policy'} className='w-full text-[12px] text-white opacity-65'>
          Privacy Policy
        </Link>
      </div>
      <div className='flex justify-end hidden'>
        <SocialMediaContainer />
      </div>
    </div>
  )

  const desktopView = (
    <div className="w-screen flex items-center justify-around bg-[#23243D] py-4 my-4">
      <div className='w-2/5 text-[10px] md:text-[12px] text-white opacity-65'>
        Copyright © 2024 KOTH Gaming - All Rights Reserved
      </div>
      <div>
        <Link to={'/privacy-policy'} className='w-full text-[10px] md:text-[12px] text-white opacity-65'>
          Privacy Policy
        </Link>
      </div>
      <div className='w-2/5 flex justify-end'>
        <SocialMediaContainer />
      </div>
    </div>
  )

  return isMobile ? mobileView : desktopView
}