import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import { Home } from './screens/Home';
import { PrivacyPolicy } from './screens/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <Home />
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App
